/** @jsx jsx */
import { Container, Flex, Heading, Text, Link, Box, Button, Grid, useThemeUI, jsx } from 'theme-ui';
import Spacer from '~/components/Generic/Layout/Spacer';
import TextMarquee from '~/components/Generic/Marquee/Text';
import RichTextRenderer from '~/components/Content/RichText';
import ContentLink from '~/components/Generic/ContentLink';
import useFooterQuery from '~/hooks/graphql/queries/use-footer';

const Contact = ({ page }) => {
  const { socials } = useFooterQuery();

  const hands = ['👋', '👋🏻', '👋🏼', '👋🏽', '👋🏾', '👋🏿'];
  const { theme } = useThemeUI();
  const {
    hero: {
      heading: { heading: heroHeading },
      subheading: { subheading: heroSubheading },
    },
    sections,
  } = page;

  return (
    <Container variant="fullwidth" sx={{ position: 'relative' }}>
      <Box
        sx={{
          position: 'absolute',
          top: ['24rem', '13rem', '9.5rem'],
          right: ['2rem', null, '4rem'],
          fontSize: ['2.5rem', '3.5rem', '7.5rem'],
          animationName: 'floating',
          animationDuration: '3s',
          animationIterationCount: 'infinite',
          animationTimingFunction: 'ease-in-out',
          animationDelay: `${parseInt(Math.random() * 2)}s`,
        }}
      >
        <span role="img">{hands[0]}</span>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: ['22rem', '25rem', '38.5rem'],
          left: ['6rem', null, '25%'],
          fontSize: ['2.5rem', '3.5rem', '4.5rem'],
          animationName: 'floating',
          animationDuration: '3s',
          animationIterationCount: 'infinite',
          animationTimingFunction: 'ease-in-out',
          animationDelay: `${parseInt(Math.random() * 2)}s`,
        }}
      >
        <span role="img">{hands[1]}</span>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: ['12.2rem', null, '3rem'],
          left: ['10%', null, '20%'],
          fontSize: ['2.5rem', '3.5rem', '4rem'],
          animationName: 'floating',
          animationDuration: '3s',
          animationIterationCount: 'infinite',
          animationTimingFunction: 'ease-in-out',
          animationDelay: `${parseInt(Math.random() * 2)}s`,
        }}
      >
        <span role="img">{hands[2]}</span>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: ['28rem', '32rem', '32rem'],
          left: ['.5rem', '2rem', '10%'],
          fontSize: ['3rem', '5.5rem', '7.5rem'],
          animationName: 'floating',
          animationDuration: '3s',
          animationIterationCount: 'infinite',
          animationTimingFunction: 'ease-in-out',
          animationDelay: `${parseInt(Math.random() * 2)}s`,
        }}
      >
        <span role="img">{hands[3]}</span>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: ['33rem', null, '38rem'],
          right: ['2rem', null, '10%'],
          fontSize: ['3.5rem', '5.5rem'],
          animationName: 'floating',
          animationDuration: '3s',
          animationIterationCount: 'infinite',
          animationTimingFunction: 'ease-in-out',
          animationDelay: `${parseInt(Math.random() * 2)}s`,
        }}
      >
        <span role="img">{hands[4]}</span>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: ['6rem', '1rem'],
          right: ['20%', '30%'],
          fontSize: ['3.5rem', '4.5rem', '6.5rem'],
          animationName: 'floating',
          animationDuration: '3s',
          animationIterationCount: 'infinite',
          animationTimingFunction: 'ease-in-out',
          animationDelay: `${parseInt(Math.random() * 2)}s`,
        }}
      >
        <span role="img">{hands[5]}</span>
      </Box>
      <Spacer height="10rem" />
      <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
        <Heading
          as="h1"
          variant="h2"
          sx={{
            textAlign: 'center',
          }}
        >
          {heroHeading}
        </Heading>
        {/** TODO: Refactor this out */}
        <Heading
          as="h3"
          sx={{
            mt: ['4.5rem'],
            maxWidth: '54.5rem',
            textAlign: 'center',
            fontFamily: 'merriweather',
            fontWeight: 700,
            fontSize: [11, 14, 17],
            lineHeight: 1.25,
            letterSpacing: '0.35px',
            width: '80%',
          }}
        >
          {heroSubheading}
        </Heading>
        {/** TODO: Refactor this out */}
        <Button
          as={Link}
          href="mailto:care@itsaugust.co"
          target="_blank"
          rel="noreferrer noopen"
          sx={{
            mt: ['3.7rem', null, '8.5rem'],
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'chatelle',
            color: 'canary',
            width: '72%',
            maxWidth: '42.1rem',
            height: '6.1rem',
            borderRadius: '21px',
            border: `1px solid ${theme.colors.canary}`,
            textTransform: 'uppercase',
            fontSize: [9, 12, 15],
            fontWeight: 800,
          }}
        >
          care@itsaugust.co
        </Button>
      </Flex>
      <Spacer height={['5rem', null, '12.1rem']} />
      <Grid
        columns={[2, 2, 3]}
        sx={{
          margin: ['8.5rem 0 0 2rem', '12.5rem 5rem 0 14rem'],
          '& > div': {
            fontSize: [5, 7, 9],
            '& > h4': {
              fontWeight: 800,
              textTransform: 'uppercase',
              width: '15rem',
              '& > p': {
                margin: 'unset',
              },
            },
            '& > h4 p': {
              mt: 0,
              mb: '1.5rem',
            },
            '& > div': {
              '& > p': {
                margin: 0,
                padding: 0,
              },
            },
          },
        }}
      >
        <Box>
          <Heading as="h4">
            <RichTextRenderer richText={sections[0].heading} />
          </Heading>
          <Text>
            <RichTextRenderer richText={sections[0].copy} />
          </Text>
        </Box>
        <Box>
          <Heading as="h4">
            <RichTextRenderer richText={sections[1].heading} />
          </Heading>
          <Text>
            <RichTextRenderer richText={sections[1].copy} />
          </Text>
        </Box>
        <Box
          sx={{
            gridRow: [3, 3, 1],
            gridColumn: ['1/3', '1/3', 3],
            margin: [
              '60px auto 0 auto !important',
              '60px auto 0 auto !important',
              '0 50px 0 0 !important',
            ],
            textAlign: ['center', null, 'left'],
          }}
        >
          <Heading as="h3" sx={{ margin: ['0 auto', null, 0] }}>
            On the Web
          </Heading>
          <Flex
            sx={{
              width: ['214px', null, '276px'],
              height: ['53px', null, '67px'],
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: 'transparent',
              mt: ['10px', '20px'],
              a: {
                height: '2.5rem',
                width: '2.5rem',
                '& > svg': { height: '100%', '& > path': { fill: 'alizarinCrimson' } },
              },
            }}
          >
            {socials.links.map((link, index) => (
              <ContentLink link={link} key={index} />
            ))}
          </Flex>
        </Box>
        <Box>
          <Heading as="h4">
            <RichTextRenderer richText={sections[2].heading} />
          </Heading>
          <Text>
            <RichTextRenderer richText={sections[2].copy} />
          </Text>
          <Text
            dangerouslySetInnerHTML={{ __html: sections[2].description?.childMarkdownRemark?.html }}
            sx={{ mt: ['1.5rem'], fontSize: [0], '& > p > a': { textDecoration: 'underline' } }}
          />
        </Box>
        <Box>
          <Heading as="h4">
            <RichTextRenderer richText={sections[3].heading} />
          </Heading>
          <Text>
            <RichTextRenderer richText={sections[3].copy} />
          </Text>
        </Box>
      </Grid>
      <Spacer height="14rem" />
      <TextMarquee
        text="JOIN THE #INNERCYCLE"
        aria-label="Innercycle group link that reads 'JOIN THE #INNERCYCLE' (opens a new tab)"
        backgroundColor="canary"
        textColor="chatelle"
        borderColor="#b5afc2"
        linkTo={{
          url: 'https://app.genevachat.com/invite/0f355b9e-b373-43ac-a8df-b843b3d6c433?n=1',
          external: true,
        }}
      />
      <Spacer height="7rem" />
    </Container>
  );
};

export default Contact;
