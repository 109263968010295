/** @jsx jsx */
import { jsx } from 'theme-ui';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from '~/components/Layout';
import Metadata from '~/components/Metadata';
import Contact from '~/components/Pages/Contact';

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query {
      page: contentfulPage(slug: { eq: "contact" }) {
        name
        hero {
          heading {
            heading
          }
          subheading {
            subheading
          }
        }
        sections {
          heading {
            raw
          }
          copy {
            raw
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  `);
  const { page } = data;
  return (
    <Layout>
      <Metadata title="Contact" description="Hit us up!" />
      <Contact page={page} />
    </Layout>
  );
};

export default AboutPage;
